import styled from "styled-components";

export const Responsibilities = styled.section`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  grid-area: 3 / 2 / 4 / -2;

  & > h2 {
    margin: 0;
  }

  @media (min-width: 760px) {
    align-items: center;
  }
`;

export const ResponsibilityDescription = styled.div`
  max-width: 65ch;
`;

export const SingleResponsibilityContainer = styled.div`
  padding: var(--size-4) 0;
  width: fit-content;

  &:first-child {
    padding-top: var(--size-2);
  }

  & h2 {
    margin: 0;
    text-align: center;
  }

  @media (min-width: 1024px) {
    padding: var(--size-6) 0;

    & h2 {
      margin-bottom: var(--size-4);
    }
  }
`;

export const Stack = styled.div<{ order: number }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: 3 / 2 / 4 / -2;

  & h2 {
    margin: 0;
    text-align: center;
  }

  @media (min-width: 768px) {
    flex-direction: ${({ order }) => (order % 2 !== 0 ? "row-reverse" : "row")};
    column-gap: var(--size-5);
  }
`;

export const TechList = styled.div<{ order: number }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: var(--size-3) 0;
  padding: 0;
  row-gap: var(--size-2);
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: column;
    row-gap: var(--size-3);
  }

  @media (min-width: 1300px) {
    align-items: ${({ order }) =>
      order % 2 !== 0 ? "flex-start" : "flex-end"};
    justify-content: flex-start;
  }
`;

export const TechItem = styled.div`
  border: 1px solid var(--primary-600);
  border-radius: var(--size-5);
  box-shadow: var(--shadow-xs);
  margin: 0 var(--size-1) 0 0;
  padding: var(--size-1) var(--size-2);
  transition: box-shadow var(--speed-md) ease-out;

  &:hover {
    box-shadow: var(--shadow-md);
  }

  &:last-child {
    margin: 0;
  }

  @media (min-width: 375px) {
    margin: 0 var(--size-1);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-left: var(--size-1);
    }
  }

  @media (min-width: 425px) {
    margin: 0 var(--size-1);

    &:first-child,
    &:last-child {
      margin: 0 var(--size-1);
    }
  }

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1300px) {
    width: fit-content;
  }
`;
