import React from "react";
import PropTypes from "prop-types";
import { StyledLine } from "./styles";

interface IProps {
  isRotated: boolean;
}

function Separator(props: IProps): JSX.Element {
  const { isRotated } = props;
  return (
    <StyledLine
      className={isRotated ? "is-rotated" : ""}
      alt="Squiggly arrow pointing down"
    />
  );
}

Separator.propTypes = {
  isRotated: PropTypes.bool,
};

Separator.defaultProps = {
  isRotated: false,
};

export default Separator;
