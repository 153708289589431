import styled from "styled-components";
import Squiggly from "../../../../static/icons/squiggly_arrow.svg";

export const StyledLine = styled(Squiggly)`
  height: auto;
  margin: var(--size-5) auto;
  width: 60px;
  z-index: -1;
  transform: rotate(-30deg) scaleX(-1);

  &.is-rotated {
    transform: rotate(30deg);
  }

  @media (min-width: 768px) {
    grid-column: 1/-1;
    margin: var(--size-8) auto var(--size-7) auto;
    width: 100px;
  }
`;

export default StyledLine;
