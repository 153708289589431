import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  ProjectPage,
  Paragraph,
  Heading,
} from "../components/layouts/ProjectPage";
import Separator from "../components/common/Separator";
import {
  Responsibilities,
  ResponsibilityDescription,
  SingleResponsibilityContainer,
  Stack,
  TechItem,
  TechList,
} from "../components/learnbrite/styles";
import projects from "../data/projects";

function LearnBrite(): JSX.Element {
  const logos = useStaticQuery(graphql`
    query {
      monty: file(relativePath: { eq: "logos/monty.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }

      gaze: file(relativePath: { eq: "logos/gaze.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
    }
  `);

  const project = projects[0];

  const seo = {
    title: "LearnBrite and Mootup",
    description: "What do I do all day? This is a good overview",
  };

  const title = {
    main: project.name,
    sub: project.sting,
  };

  const otherProjects = [
    {
      title: "Gaze of the World",
      url: "/gaze",
      thumbnail: logos.gaze.childImageSharp.gatsbyImageData,
    },
    {
      title: "The Full Monty",
      url: "/monty",
      thumbnail: logos.monty.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <ProjectPage
      seo={seo}
      notice={project.notice}
      title={title}
      otherProjects={otherProjects}
    >
      <Responsibilities>
        {project.additional.map((area, index) => (
          <React.Fragment key={area.key}>
            <SingleResponsibilityContainer>
              <Heading>{area.title}</Heading>
              <Stack order={index}>
                <TechList order={index}>
                  {area.stack &&
                    area.stack.map((tech) => (
                      <TechItem key={tech}>{tech}</TechItem>
                    ))}
                </TechList>
                <ResponsibilityDescription>
                  <Paragraph dangerouslySetInnerHTML={area} />
                </ResponsibilityDescription>
              </Stack>
            </SingleResponsibilityContainer>
            {index < project.additional.length - 1 && (
              <Separator isRotated={index % 2 !== 0} />
            )}
          </React.Fragment>
        ))}
      </Responsibilities>
    </ProjectPage>
  );
}

export default LearnBrite;
