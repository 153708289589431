import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { IDangerouslySetInnerHtml } from "../../../types/global";
import { linkStyle } from "../../common/Link/styles";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(60%, 10fr) 1fr;
  grid-row-gap: var(--size-5);
  padding: var(--size-7) 0;

  @media (min-width: 768px) {
    grid-row-gap: var(--size-5);
  }

  @media (min-width: 1024px) {
    grid-row-gap: var(--size-6);
  }

  @media (min-width: 1300px) {
    grid-row-gap: var(--size-7);
  }

  @media (min-width: 2560px) {
    grid-template-columns:
      minmax(var(--size-9), 1fr)
      auto
      minmax(var(--size-9), 1fr);
  }
`;

export const HeadingText = styled.h2`
  font-size: var(--font-size-md);
  margin: 0;

  @media (min-width: 1024px) {
    font-size: var(--font-size-xl);
  }

  @media (min-width: 2560px) {
    font-size: var(--font-size-x2l);
  }
`;

export const Notice = styled.div`
  border: 1px solid var(--primary-600);
  border-radius: var(--size-10);
  box-shadow: var(--shadow-sm);
  font-size: var(--font-size-x3s);
  grid-area: 2 / 2 / 3 / -2;
  hyphens: auto;
  margin: 0 auto;
  max-width: 70ch;
  padding: var(--size-3) var(--size-4);

  @media (min-width: 1024px) {
    padding: var(--size-4) var(--size-5);
    font-size: var(--font-size-x2s);
  }
`;

export const OtherProjectsContainer = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  grid-area: 4 / 2 / 5 / -2;
  justify-content: space-between;
  justify-self: center;
  max-width: max-content;
  padding-top: var(--size-4);
  row-gap: var(--size-4);

  & > h3 {
    margin: 0;
    text-align: center;
    width: 100%;
  }

  & > div {
    justify-content: space-between;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: var(--size-2);
    width: 40%;
  }
`;

interface IOtherProjectProps {
  side: "left" | "right";
}
export const OtherProject = styled(Link)<IOtherProjectProps>`
  color: inherit;
  max-width: 35vw;
  text-align: ${({ side }) => side};
  text-decoration: none;
`;

export const OtherProjectArrow = styled.div`
  font-size: var(--font-size-md);
  width: 100%;
`;

interface IOtherProjectThumbnailProps {
  side: string;
  alt: string;
}
export const OtherProjectThumbnail = styled(GatsbyImage)<IOtherProjectThumbnailProps>`
  margin: ${({ side }) => (side === "left" ? "0 auto 0 0" : "0 0 0 auto")};
  width: var(--size-9);

  @media (min-width: 768px) {
    width: var(--size-10);
  }
`;

interface IOtherProjectTextProps {
  side: string;
}
export const OtherProjectText = styled.div<IOtherProjectTextProps>`
  text-align: ${({ side }) => side};
`;

interface IParagraphTextProps {
  children?: React.ReactNode;
  dangerouslySetInnerHTML?: IDangerouslySetInnerHtml;
}
export const ParagraphText = styled.p<IParagraphTextProps>`
  font-size: var(--font-size-xs);
  margin: 0;

  & a {
    ${linkStyle};
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-sm);
  }

  @media (min-width: 2560px) {
    font-size: var(--font-size-md);
  }
`;

export const SubheadingText = styled.h3`
  font-size: var(--font-size-sm);
  margin: 0;

  @media (min-width: 1024px) {
    font-size: var(--font-size-md);
  }

  @media (min-width: 2560px) {
    font-size: var(--font-size-xl);
  }
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: 1 / 2 / 2 / -2;
  justify-content: center;
  margin: var(--size-5) 0 0 0;

  & > h1 {
    font-size: var(--font-size-lg);
    margin: 0;
    text-align: center;
  }

  & > span {
    font-size: var(--font-size-sm);
    font-variant: all-petite-caps;
    text-align: center;
  }

  @media (min-width: 1024px) {
    & > h1 {
      font-size: var(--font-size-x2l);
    }

    & > span {
      font-size: var(--font-size-xl);
    }
  }

  @media (min-width: 2560px) {
    & > h1 {
      font-size: var(--font-size-x3l);
    }

    & > span {
      font-size: var(--font-size-x2l);
    }
  }
`;
