import { IDangerouslySetInnerHtml } from "../types/global";

export interface IProjectPurpose extends IDangerouslySetInnerHtml {
  key: number;
}

export interface IProjectAdditional extends IDangerouslySetInnerHtml {
  key: number;
  title: string;
  stack?: string[];
}

export interface IProject {
  id: number;
  description: string;
  name: string;
  sting: string;
  stack?: string[];
  stackExplanation?: string;
  purpose?: IProjectPurpose[];
  additional: IProjectAdditional[];
  tags: string[];
  url: string;
  notice?: IDangerouslySetInnerHtml;
}

const projects: IProject[] = [
  {
    id: 0,
    name: "LearnBrite/MootUp",
    sting: "What do I do all day?",
    description: `My current places of work, where the boundaries of what's possible in learning and conferencing (and our infrastructure :D) are pushed just a bit further every day`,
    tags: ["Infrastructure & scaling", "Api design", "Development"],
    url: "/learnbrite",
    additional: [
      {
        key: 0,
        title: "PHP development",
        stack: ["SQL", "Redis", "NoSQL", "Laravel"],
        __html: `Within <a href="https://learnbrite.com" target="_blank" rel="noopener noreferrer">LearnBrite</a> and <a href="https://mootup.com" target="_blank" rel="noopener noreferrer">MootUp</a> one of my main responsibilities is PHP development, which very often 
      involves Laravel.<br><br>Areas of concerns that are are relevant to this application include authentication (especially user
      access and management), error tracking and analytics, API design and security, and of course resource management within 
      the standard CRUD paradigm.`,
      },
      {
        key: 1,
        title: "Javascript development",
        stack: ["Websockets", "WebRTC", "jQuery", "Node", "Gulp"],
        __html: `Another sizeable part of my work includes development and mainenance of complex Javascript apps, mainly a browser-based 
      3D environment for conferencing and e-learning.<br><br>A short list of features includes a fully editable 3D environment and avatars, 
      multi-user with different user roles/permissions, conferencing (audio & video, chat, and a lot of integrations), 
      polls and Q&As, interactive bots with a full conversation editor, user-programmable interactions, and a lot more!`,
      },
      {
        key: 2,
        title: "Scaling infrastructure, services, and APIs",
        stack: [
          "Varnish",
          "HAProxy",
          "Dark CDN magicks",
          "Kubernetes",
          "Docker",
        ],
        __html: `With the full-stack territory come full-stack concerns, and devops is certainly a big one!<br><br>My responsibilities in this area
        range from simple containerization with Docker, to scaling our services ever-upward with Kubernetes, to serving a frankly massive amount of 
        requests on ResponsiveVoice.`,
      },
      {
        key: 3,
        title: "Meta-productive work",
        stack: ["Zapier", "Jenkins", "Bits & pieces"],
        __html: `Last, but absolutely among my favourite things, is what I call "meta-productive" work, meaning standardizing, optimizing, 
        automating, and documenting company processes so that everyone from CEO to Support staff has more time they can dedicate to important tasks.`,
      },
    ],
  },
  {
    id: 1,
    name: "Gaze of the World",
    sting: "Visual trends in news about nations",
    description: `Trying to escape from the daily news cycle, I started looking at the coverage of countries by volume to see 
    "where the world was looking". It was a naïve project, but an important stepping stone towards a deeper understanding of data!`,
    tags: ["Data analysis", "Scraping"],
    url: "gaze",
    purpose: [
      {
        key: 0,
        __html: `The impetus behind Gaze of the World was to glean information about world events by analyzing 
     which countries were being covered by news websites - to see "where the world is looking". 
     If coverage of Italy doubles in a single day something probably happened there, and this
     principle would allow me to learn about major events across the globe without spending the 
     entire day reading newspapers`,
      },
      {
        key: 1,
        __html: `The project was first launched in the middle of 2016, and collected data for a couple of
     years before being shut down; it survives <a href="https://web.archive.org/web/20180903084842/https://gazeofthe.world/" target="_blank" rel="noopener noreferrer">on the Internet Archive</a>,
      albeit in a less interactive form, and <a href="https://twitter.com/gazeoftheworld" target="_blank" rel="noopener noreferrer">on Twitter</a>`,
      },
    ],
    stack: ["Nginx", "PHP", "Laravel", "SQL"],
    stackExplanation: `The aim of this project was to learn how to extract meaning from data, while also improving my 
    knowledge of Laravel. I never planned for it to grow except in database size, so scale wasn't a consideration, and 
    ultimately the default LEMP stack was chosen as it closely matched what I used at work, offering a great opportunity 
    for full-stack practice.`,
    additional: [
      {
        key: 0,
        title: `Source diversity is paramount`,
        __html: `Despite trying to avoid a western-centric perspective by scraping for news in a number of lingua francas,
        due to my higher familiarity with european languages the overall amount of sources was still skewed towards the 
        anglosphere, resulting in much higher coverage and thus overall visibility. <b>You cannot fight bias without
        investing a lot of effort into ensuring the diversity of your sources</b>.`,
      },
      {
        key: 1,
        title: `Data is fragile`,
        __html: `Coverage was very low for some countries, sometimes even close to 0. As I was compiling a "top 10" of 
        countries by the % difference in coverage from the daily average, those with low averages were extremely volatile, 
        which in turn often hid when something of import was happening while highlighting something irrelevant in a country
        with more significant coverage. <b>Bad inputs taint conclusions, even if those conclusions are made in good faith</b>.`,
      },
      {
        key: 2,
        title: `Data has stories to tell`,
        __html: `Before I decided to pull the plug on the project, I remember how struck I was by the cadence of news during
        Trump's US presidency, by the ebb and flow of weekly, monthly, and seasonal news, and by how criminally underrepresented
        some countries were in coverage. Coincidences certainly happen, but an incredible amount of meaning can be found in a 
        dataset analyzed with a critical mind; <b>you can see humanity's shadow in the smallest set of data</b> (but you shouldn't
        let that notion make you see correlations where there are none 😉)`,
      },
    ],
  },
  {
    id: 2,
    name: "The Full Monty",
    sting: "There's no hiding from reality",
    description: `Turns out, Quantified Self techniques are wonderful for self-knowledge when applied with restraint!`,
    tags: ["Data-driven learning"],
    url: "monty",
    notice: {
      __html: "This project is a work in progress!",
    },
    purpose: [
      {
        key: 0,
        __html: `Initially there was the idea (not mine) of using a Monte Carlo simulation to estimate how long certain tasks would take. That turned out to be quite useful, offering a statistical counterweight to my intuition and "stripping" it of self-deception (hence the name and logo).`,
      },
      {
        key: 1,
        __html: `The Quantified Self philosophy is notoriously easy to overdo: when you start tracking and analyzing something, you get the urge to do the same for absolutely everything else, which makes this project both a data-driven exploration of certain aspects of my life and work, as well as an excercise in self-restraint.`,
      },
    ],
    additional: [
      {
        key: 0,
        title: `Understanding`,
        __html: `Ultimately, the main goal of this project has always been understanding, whether I decide to act upon that understanding or not.<br>For example, I use Full Monty to adjust the estimates I give when talking with co-workers about ETAs, and I'm actively trying to reduce the variance between my original estimate and the simulation.<br>When it comes to tracking the volume of the packages I receive, however, I'm just kinda <i>curious</i> to see if there are trends in my behaviour.`,
      },
      {
        key: 1,
        title: `Skill building`,
        __html: `Part of the impetus for Full Monty to be a web-app instead of a native one is simply that I wanted more exposure to tools we don't use often (or at all) in my day-to-day work, for example NextJS and GraphQL. This actually made the project suffer a bit since some technological forces were dictated by what I wanted use versus what might have been best for its scope (e.g. React vs Svelte, MongoDB vs SQLite, a web-app vs a native or Electron one).`,
      },
    ],
    stack: ["NodeJS", "React", "NextJS", "MongoDB", "SQLite", "Docker"],
    stackExplanation: `This project is as much for my edification as it is for keeping my skills fresh when it comes to current industry standards, and the stack therefore leans heavily towards React. The choice of datastore was largely dictated by wanting flexibility, but the data turned out to be quite well-defined, which resulted in a switch from MongoDB to SQLite for portability (and removing unnecessary complexity).`,
  },
];

export default projects;
