import { IGatsbyImageData } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import { IDangerouslySetInnerHtml } from "../../../types/global";
import Footer from "../../common/Footer";
import Layout from "../../common/Layout";
import Nav from "../../common/Nav";
import SEO from "../../common/SEO";
import {
  Grid,
  HeadingText,
  Notice,
  OtherProject,
  OtherProjectArrow,
  OtherProjectsContainer,
  OtherProjectText,
  OtherProjectThumbnail,
  ParagraphText,
  SubheadingText,
  Title,
} from "./styles";

interface ITitle {
  main: string;
  sub: string;
}

interface ISeo {
  title: string;
  description: string;
}

interface IOtherProject {
  title: string;
  url: string;
  thumbnail: IGatsbyImageData;
}

interface IProps {
  title: ITitle;
  children?: React.ReactNode | React.ReactNode[];
  notice: IDangerouslySetInnerHtml;
  seo: ISeo;
  otherProjects: IOtherProject[];
}

export function ProjectPage({ title, children, notice, seo, otherProjects }: IProps): JSX.Element {
  const [leftProject, rightProject] = otherProjects;

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} />
      <Nav />
      <Grid>
        <Title>
          <h1>{title.main}</h1>
          <span>{title.sub}</span>
        </Title>
        {notice && <Notice dangerouslySetInnerHTML={notice} />}
        {children}
        <OtherProjectsContainer>
          <h3>Here are more projects</h3>
          <OtherProject to={leftProject.url} side="left">
            <OtherProjectThumbnail side="left" image={leftProject.thumbnail} alt="" />
            <OtherProjectText side="left">
              <OtherProjectArrow>↽</OtherProjectArrow>
              <span>{leftProject.title}</span>
            </OtherProjectText>
          </OtherProject>
          <OtherProject to={rightProject.url} side="right">
            <OtherProjectThumbnail side="right" image={rightProject.thumbnail} alt="" />
            <OtherProjectText side="right">
              <OtherProjectArrow>⇁</OtherProjectArrow>
              <span>{rightProject.title}</span>
            </OtherProjectText>
          </OtherProject>
        </OtherProjectsContainer>
      </Grid>
      <Footer />
    </Layout>
  );
}

ProjectPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  title: PropTypes.shape({
    main: PropTypes.string.isRequired,
    sub: PropTypes.string.isRequired,
  }).isRequired,
  notice: PropTypes.shape({
    __html: PropTypes.string,
  }),
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  otherProjects: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({}).isRequired,
    })
  ).isRequired,
};

ProjectPage.defaultProps = {
  notice: null,
};

interface IParagraphProps {
  children?: React.ReactNode;
  dangerouslySetInnerHTML?: IDangerouslySetInnerHtml;
}
export function Paragraph({ children, dangerouslySetInnerHTML }: IParagraphProps): JSX.Element {
  if (dangerouslySetInnerHTML) {
    return <ParagraphText dangerouslySetInnerHTML={dangerouslySetInnerHTML} />;
  }

  return <ParagraphText>{children}</ParagraphText>;
}

Paragraph.defaultProps = {
  children: "",
};

interface Props {
  children: React.ReactNode;
}

// eslint-disable-next-line react/prop-types
export function Heading(props: Props): JSX.Element {
  return <HeadingText>{props.children}</HeadingText>;
}

// eslint-disable-next-line react/prop-types
export function Subheading(props: Props): JSX.Element {
  return <SubheadingText>{props.children}</SubheadingText>;
}
